<template>
  <form>
    <b-field label="Denominação Social">
      <b-input
        disabled
        placeholder="Denominação Social"
        :value="companyHistory.socialDenomination"
      ></b-input>
    </b-field>

    <b-field label="Nome Fantasia">
      <b-input disabled placeholder="Nome Fantasia" :value="companyHistory.fantasyName"></b-input>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field expanded label="Natureza Jurídica">
        <b-select
          disabled
          expanded
          placeholder="Natureza Jurídica"
          :value="companyHistory.legalNature"
        >
          <option v-for="option in legalNatureOptions" :key="option.id" :value="option.id">{{
            option.name
          }}</option>
        </b-select>
      </b-field>

      <b-field expanded label="Atividades">
        <b-select disabled expanded placeholder="Atividades" :value="companyHistory.activity">
          <option v-for="activity in activitiesOptions" :key="activity.id" :value="activity.id">{{
            activity.name
          }}</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field expanded label="Capital Social">
        <b-input
          disabled
          placeholder="Capital Social"
          v-mascara:valor
          :value="companyHistory.shareCapital ? formatValue(companyHistory.shareCapital, 2) : ''"
          @keypress.native="teclasPermitidasValor($event)"
        ></b-input>
      </b-field>

      <b-field expanded label="Data Capital Social">
        <b-datepicker
          disabled
          editable
          placeholder="Data Capital Social"
          icon="calendar-today"
          v-mascara:data
          :value="companyHistory.shareCapitalDate"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        ></b-datepicker>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field expanded label="CNPJ">
        <b-input
          disabled
          placeholder="CNPJ"
          maxlength="18"
          :has-counter="false"
          v-mascara:cnpj
          :value="companyHistory.cnpj"
        ></b-input>
      </b-field>

      <b-field expanded label="Inscrição Estadual">
        <b-input
          disabled
          placeholder="Inscrição Estadual"
          maxlength="15"
          :has-counter="false"
          v-mascara:inscricaoEstadual
          :value="companyHistory.stateRegistration"
        ></b-input>
      </b-field>

      <b-field expanded label="Inscrição Municipal">
        <b-input
          disabled
          placeholder="Inscrição Municipal"
          :value="companyHistory.municipalRegistration"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </b-field>
    </b-field>

    <hr />

    <b-field grouped class="column-direction-touch">
      <b-field label="CEP">
        <b-input
          disabled
          placeholder="CEP"
          maxlength="9"
          :has-counter="false"
          :value="companyHistory.zipCode"
        />
      </b-field>

      <b-field expanded label="Endereço">
        <b-input disabled placeholder="Endereço" :value="companyHistory.address"></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field label="Número">
        <b-input disabled placeholder="Número" :value="companyHistory.number" />
      </b-field>

      <b-field expanded label="Complemento">
        <b-input disabled placeholder="Complemento" :value="companyHistory.complement" />
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field label="Bairro">
        <b-input disabled placeholder="Bairro" :value="companyHistory.neighborhood" />
      </b-field>

      <b-field expanded label="Município">
        <b-input disabled placeholder="Município" :value="companyHistory.city" />
      </b-field>

      <b-field expanded label="UF">
        <b-input disabled placeholder="UF" :value="companyHistory.state" />
      </b-field>
    </b-field>

    <hr />

    <b-field grouped class="column-direction-touch">
      <b-field label="Telefone">
        <b-input
          disabled
          maxlength="12"
          :has-counter="false"
          v-mascara:telefone
          placeholder="Telefone"
          :value="companyHistory.telephone"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </b-field>

      <b-field label="Celular">
        <b-input
          disabled
          placeholder="Celular"
          maxlength="13"
          :has-counter="false"
          v-mascara:telefone
          :value="companyHistory.cellphone"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </b-field>

      <b-field expanded label="E-mail">
        <b-input disabled placeholder="E-mail" :value="companyHistory.email"></b-input>
      </b-field>
    </b-field>

    <hr />

    <b-field expanded label="Responsável Cobrança">
      <b-input
        disabled
        expanded
        placeholder="Responsável Cobrança"
        :value="companyHistory.billingResponsible"
      ></b-input>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field label="Telefone Cobrança">
        <b-input
          disabled
          placeholder="Telefone Cobrança"
          maxlength="12"
          :has-counter="false"
          v-mascara:telefone
          :value="companyHistory.billingTelephone"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field label="Celular Cobrança">
        <b-input
          disabled
          placeholder="Celular Cobrança"
          maxlength="13"
          :has-counter="false"
          v-mascara:telefone
          :value="companyHistory.billingCellphone"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field expanded label="E-mail Cobrança">
        <b-input
          disabled
          expanded
          placeholder="E-mail"
          :value="companyHistory.billingEmail"
          @input="data.billingEmail = data.billingEmail.toLowerCase()"
        />
      </b-field>
    </b-field>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { legalNatureOptions, activitiesOptions } from '@/utils/data';

import { formatarValor as formatValue } from '@/utils/format';

export default {
  name: 'ViewCompanyData',
  data() {
    return {
      activitiesOptions,
      formatValue,
      legalNatureOptions,
    };
  },
  computed: {
    ...mapState('companies', ['companyHistory']),
  },
};
</script>
